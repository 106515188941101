import type {FC} from 'react';
import React, {useCallback, useRef, useState} from 'react';
import {Avatar, Box, ButtonBase} from '@mui/material';
import {AccountPopover} from './account-popover';
import {getInitials} from "../../utils/get-initials";
import {useSession} from "next-auth/react";

export const AccountButton: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const {data} = useSession()
  const handlePopoverOpen = useCallback(
    (): void => {
      setOpenPopover(true);
    },
    []
  );

  const handlePopoverClose = useCallback(
    (): void => {
      setOpenPopover(false);
    },
    []
  );

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handlePopoverOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
        >
          {getInitials(data?.user.name)}
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handlePopoverClose}
        open={openPopover}
      />
    </>
  );
};
