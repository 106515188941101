import { mystesApi as api } from "./index";
export const addTagTypes = ["majors"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      majorsList: build.query<MajorsListApiResponse, MajorsListApiArg>({
        query: () => ({ url: `/api/v1/majors/` }),
        providesTags: ["majors"],
      }),
      majorsCreate: build.mutation<MajorsCreateApiResponse, MajorsCreateApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v1/majors/`,
            method: "POST",
            body: queryArg.major,
          }),
          invalidatesTags: ["majors"],
        }
      ),
      majorsRetrieve: build.query<
        MajorsRetrieveApiResponse,
        MajorsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/majors/${queryArg.id}/` }),
        providesTags: ["majors"],
      }),
      majorsUpdate: build.mutation<MajorsUpdateApiResponse, MajorsUpdateApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v1/majors/${queryArg.id}/`,
            method: "PUT",
            body: queryArg.major,
          }),
          invalidatesTags: ["majors"],
        }
      ),
      majorsPartialUpdate: build.mutation<
        MajorsPartialUpdateApiResponse,
        MajorsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/majors/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedMajor,
        }),
        invalidatesTags: ["majors"],
      }),
      majorsDestroy: build.mutation<
        MajorsDestroyApiResponse,
        MajorsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/majors/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["majors"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as mystesApi };
export type MajorsListApiResponse = /** status 200  */ Major[];
export type MajorsListApiArg = void;
export type MajorsCreateApiResponse = /** status 201  */ Major;
export type MajorsCreateApiArg = {
  major: Major;
};
export type MajorsRetrieveApiResponse = /** status 200  */ Major;
export type MajorsRetrieveApiArg = {
  /** A unique integer value identifying this major. */
  id: number;
};
export type MajorsUpdateApiResponse = /** status 200  */ Major;
export type MajorsUpdateApiArg = {
  /** A unique integer value identifying this major. */
  id: number;
  major: Major;
};
export type MajorsPartialUpdateApiResponse = /** status 200  */ Major;
export type MajorsPartialUpdateApiArg = {
  /** A unique integer value identifying this major. */
  id: number;
  patchedMajor: PatchedMajor;
};
export type MajorsDestroyApiResponse = unknown;
export type MajorsDestroyApiArg = {
  /** A unique integer value identifying this major. */
  id: number;
};
export type FormTypeEnum = 0 | 1;
export type Major = {
  id: number;
  fullname: string;
  name: string;
  slug: string;
  form_type?: FormTypeEnum;
};
export type PatchedMajor = {
  id?: number;
  fullname?: string;
  name?: string;
  slug?: string;
  form_type?: FormTypeEnum;
};
export const {
  useMajorsListQuery,
  useLazyMajorsListQuery,
  useMajorsCreateMutation,
  useMajorsRetrieveQuery,
  useLazyMajorsRetrieveQuery,
  useMajorsUpdateMutation,
  useMajorsPartialUpdateMutation,
  useMajorsDestroyMutation,
} = injectedRtkApi;
