import type {FC} from 'react';
import {useCallback, useState} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {IconButton, Tooltip} from '@mui/material';
import {SearchDialog} from './search-dialog';
import {InstantSearch} from "react-instantsearch";
import algoliasearch, {SearchClient} from "algoliasearch";

const algoliaClient = algoliasearch('5DVGLD2VMI', '3801fa34f4e738f95c5a32d466451893');

const searchClient: SearchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const Hit: FC = (props) => {
  return (
    <>

    </>
  )
}


export const SearchButton: FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpen = useCallback(
    (): void => {
      setOpenDialog(true);
    },
    []
  );

  const handleClose = useCallback(
    (): void => {
      setOpenDialog(false);
    },
    []
  );

  return (
    <>
      <Tooltip title="Rechercher">
        <IconButton onClick={handleOpen}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <InstantSearch searchClient={searchClient} indexName="mystes_prod_Application">
        <SearchDialog
          onClose={handleClose}
          open={openDialog}
        />
      </InstantSearch>
    </>
  );
};
