
export interface Paths {
  [key: string]: string
}

export const paths = {
  index: "/",
  account: "/account",
  auth: {
    login: "/auth/login",
    logout: "/auth/logout"
  },
  error: {
    error401: "/401",
    error403: "/403",
    error404: "/404",
    error500: "/500",
  },
  majors: {
    index: "/majors",
    create: "/majors/create",
    detail: "/majors/[id]",
    edit: "/majors/[id]/edit",
  },
  operations: {
    index: "/operations"
  },
  sessions: {
    index: "/sessions",
    create: "/sessions/create",
    detail: "/sessions/[id]",
    edit: "/sessions/[id]/edit",
  },
  applications: {
    index: "/applications",
    create: "/applications/create",
    detail: "/applications/[id]",
    edit: "/applications/[id]/edit",
  },
}

export const getUrl = (pathname: string, data?: { [x: string]: string | number; }) => {
  let url = pathname
  if (!data) {
    return url
  }
  let key: keyof typeof data;
  for (key in data) {
    const urlObj = data[key].toString()
    url = url.replace(`[${key}]`, urlObj)
  }
  return url
}