import { mystesApi as api } from "./index";
export const addTagTypes = ["sessions"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      sessionsList: build.query<SessionsListApiResponse, SessionsListApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/sessions/`,
          params: { accept_applications: queryArg.acceptApplications },
        }),
        providesTags: ["sessions"],
      }),
      sessionsCreate: build.mutation<
        SessionsCreateApiResponse,
        SessionsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/sessions/`,
          method: "POST",
          body: queryArg.session,
        }),
        invalidatesTags: ["sessions"],
      }),
      sessionsRetrieve: build.query<
        SessionsRetrieveApiResponse,
        SessionsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/sessions/${queryArg.id}/` }),
        providesTags: ["sessions"],
      }),
      sessionsUpdate: build.mutation<
        SessionsUpdateApiResponse,
        SessionsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/sessions/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.session,
        }),
        invalidatesTags: ["sessions"],
      }),
      sessionsPartialUpdate: build.mutation<
        SessionsPartialUpdateApiResponse,
        SessionsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/sessions/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedSession,
        }),
        invalidatesTags: ["sessions"],
      }),
      sessionsDestroy: build.mutation<
        SessionsDestroyApiResponse,
        SessionsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/sessions/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["sessions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as mystesApi };
export type SessionsListApiResponse = /** status 200  */ Session[];
export type SessionsListApiArg = {
  acceptApplications?: boolean;
};
export type SessionsCreateApiResponse = /** status 201  */ Session;
export type SessionsCreateApiArg = {
  session: Session;
};
export type SessionsRetrieveApiResponse = /** status 200  */ Session;
export type SessionsRetrieveApiArg = {
  /** A unique integer value identifying this session. */
  id: number;
};
export type SessionsUpdateApiResponse = /** status 200  */ Session;
export type SessionsUpdateApiArg = {
  /** A unique integer value identifying this session. */
  id: number;
  session: Session;
};
export type SessionsPartialUpdateApiResponse = /** status 200  */ Session;
export type SessionsPartialUpdateApiArg = {
  /** A unique integer value identifying this session. */
  id: number;
  patchedSession: PatchedSession;
};
export type SessionsDestroyApiResponse = unknown;
export type SessionsDestroyApiArg = {
  /** A unique integer value identifying this session. */
  id: number;
};
export type Session = {
  id: number;
  name: string;
  year?: number | null;
  number: number | null;
  accept_applications?: boolean;
  major: number;
};
export type PatchedSession = {
  id?: number;
  name?: string;
  year?: number | null;
  number?: number | null;
  accept_applications?: boolean;
  major?: number;
};
export const {
  useSessionsListQuery,
  useLazySessionsListQuery,
  useSessionsCreateMutation,
  useSessionsRetrieveQuery,
  useLazySessionsRetrieveQuery,
  useSessionsUpdateMutation,
  useSessionsPartialUpdateMutation,
  useSessionsDestroyMutation,
} = injectedRtkApi;
