import type {FC} from 'react';
import PropTypes from 'prop-types';
import {SxProps} from '@mui/system';
import {Box, Stack, Typography} from '@mui/material';


interface TenantSwitchProps {
  sx?: SxProps;
}

export const TenantSwitch: FC<TenantSwitchProps> = (props) => {

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        {...props}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="inherit"
            variant="h6"
          >
            Mystes
          </Typography>
          <Typography
            color="neutral.400"
            variant="body2"
          >
            École de Guerre Économique
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

TenantSwitch.propTypes = {
  // @ts-ignore
  sx: PropTypes.object
};
