import type {FC, SyntheticEvent} from 'react';
import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {Tip} from '../../components/tip';
import {Index, useHits, useSearchBox, UseSearchBoxProps} from "react-instantsearch";
import {getInitials} from "../../utils/get-initials";
import {paths} from "../../paths";
import NavLink from "../../components/NavLink";

interface SearchDialogProps {
  onClose?: () => void;
  open?: boolean;
}

interface HitsProps {
  onClose?: () => void;
}



const queryHook: UseSearchBoxProps['queryHook'] = (query, search) => {
  search(query);
};

const ApplicationsHits: FC<HitsProps> = (props) => {
  const {onClose} = props
  const {hits, sendEvent} = useHits<{ [key: string]: string }>({});
  return hits.length > 0 ? (
    <List
      subheader={
        <ListSubheader>
          Candidatures
        </ListSubheader>
      }
    >
      {hits.map((hit) => {
        const name = `${hit.candidate_firstname} ${hit.candidate_lastname.toUpperCase()}`
        return (
          <>
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <IconButton edge="end" aria-label="show">
                  <SearchIcon/>
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={undefined}
                >
                  {getInitials(name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <NavLink
                    href={{
                      pathname: paths.applications.detail,
                      query: {id: hit.id}
                    }}
                    onClick={onClose}
                    color="inherit"
                    variant="subtitle1"
                  >
                    {`${hit.candidate_lastname.toUpperCase()} ${hit.candidate_firstname}`}
                  </NavLink>
                }
                secondary={
                  <NavLink
                    href={{
                      pathname: paths.sessions.detail,
                      query: {id: hit.session_id}
                    }}
                    onClick={onClose}
                    color="inherit"
                    variant="subtitle2"
                  >
                    {`${hit.session}`}
                  </NavLink>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li"/>
          </>
        )
      })}

    </List>) : <></>
}


const SessionHits: FC<HitsProps> = (props) => {
  const {onClose} = props
  const { hits, sendEvent } = useHits<{[key: string]: string}>({});
  return hits.length > 0 ? (<List
      subheader={
        <ListSubheader >
          Promotions
        </ListSubheader>
      }
    >
      {hits.map((hit) => {
        return (
          <>
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <IconButton edge="end" aria-label="show">
                  <SearchIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={undefined}
                >
                  {getInitials(hit.name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <NavLink
                    href={{
                      pathname: paths.sessions.detail,
                      query: {id: hit.objectID}
                    }}
                    onClick={onClose}
                    color="inherit"
                    variant="subtitle1"
                  >
                    {hit.name}
                  </NavLink>
                }
                secondary={
                  <Typography
                    color="text.secondary"
                    variant="subtitle2"
                  >
                    {`Année ${hit.year}`}
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        )
      })}

    </List>) : <></>
}

export const SearchDialog: FC<SearchDialogProps> = (props) => {
  const { onClose, open = false, ...other } = props;
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayArticles, setDisplayArticles] = useState<boolean>(false);

  const {
    query,
    refine,
    clear,
  } = useSearchBox({
    queryHook,
  });

  const handleSubmit = useCallback(
    async (event: SyntheticEvent): Promise<void> => {
      event.preventDefault();
      setIsLoading(true);
      if (value.length === 0){
        clear()
      }
      else {
        refine(value);
      }
      setIsLoading(false);
    },
    [clear, refine, value]
  );

  return (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        open={open}
        {...other}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            py: 2
          }}
        >
          <Typography variant="h6">
            Recherche
          </Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{pt: 0}}>
          <Tip message="Entrez vos mots-clés et appuyez sur Entrer" />
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 3, mb: 1 }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              label="Recherche"
              onChange={(event): void => setValue(event.target.value)}
              placeholder="..."
              value={value}
            />
          </Box>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 3
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Index indexName="mystes_prod_Session">
            <SessionHits onClose={onClose} />
          </Index>
          <Index indexName="mystes_prod_Application">
            <ApplicationsHits onClose={onClose}/>
          </Index>
        </DialogContent>
      </Dialog>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
