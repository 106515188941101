import type {FC} from 'react';
import {useCallback} from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import PersonIcon from '@mui/icons-material/Person';
import {Box, Button, Divider, ListItemButton, ListItemIcon, ListItemText, Popover, Typography} from '@mui/material';
import {signOut, useSession} from "next-auth/react";
import {paths} from "../../paths";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { data } = useSession();
  const handleLogout = useCallback(
    async (): Promise<void> => {
      try {
        onClose?.();
        await signOut({callbackUrl: paths.auth.login});
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
      }
    },
    [onClose]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          {data?.user.name}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {data?.user.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={NextLink}
          href={paths.account}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Mon compte
              </Typography>
            )}
          />
        </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          Déconnexion
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
