import type {ReactNode} from 'react';
// @ts-ignore
import type {TFunction} from 'react-i18next';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExploreIcon from '@mui/icons-material/Explore';
import {Session} from "../api/sessions";
import {Major} from "../api/majors";
import {getUrl, paths} from "../paths";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import HomeIcon from '@mui/icons-material/Home';

interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const getSections = (t: TFunction, sessions: Session[], majors: Major[]): Section[] => {

  const sessionsItems = sessions.map(session => {
    const path = getUrl(paths.sessions.detail, {id: session.id})
    return {
      major: session.major,
      item: {
        title: session.name,
        path: path
      }
    }
  })

  const majorsItems = majors.map(major => {
    const path = getUrl(paths.majors.detail, {id: major.id})
    return {
      title: major.name,
      path: path,
      items: sessionsItems.filter(item => item.major === major.id).map(item => item.item)
    }
  })

  return [
    {
      subheader: t('General'),
      items: [
        {
          title: t('Tableau de bord'),
          path: paths.index,
          icon: <HomeIcon />,
        },
        {
          title: t('Accès rapide'),
          icon: <ExploreIcon />,
          items: majorsItems
        },
      ]
    },
    {
      subheader: t('Données'),
      items : [
        {
          title: t('Candidatures'),
          icon: <PersonIcon />,
          items: [
            {
              title: t('Liste'),
              path: paths.applications.index,
            },
            {
              title: t('Ajouter'),
              path: paths.applications.create,
            }
          ]
        },
        {
          title: t('Promotions'),
          path: paths.sessions.index,
          icon: <GroupsIcon />,
          items: [
            {
              title: t('Liste'),
              path: paths.sessions.index
            },
            {
              title: t('Ajouter'),
              path: paths.sessions.create,
            }
          ]
        },
        {
          title: t('Formations'),
          path: paths.majors.index,
          icon: <SchoolIcon/>,
          items: [
            {
              title: t('Liste'),
              path: paths.majors.index
            },
            {
              title: t('Ajouter'),
              path: paths.majors.create,
            }
          ]
        }
      ]
    },
    {
      subheader: t('Paramètres'),
      items: [
        {
          title: t('Mon compte'),
          path: paths.account,
          icon: <AccountCircleIcon />
        }
      ]
    }
  ];
}

